/* global dom */

import setValidations from '../lib/setValidations.js'
import onPreferencesSubmit from './user-preferences-for-event/on-preferences-submit.js'

const onlineMeetingInfo = !!document.querySelector('[name="user_preferences_for_event[online_meeting_info]"]')

let constraints = {
  'user_preferences_for_event.time_zone': {
    presenceIf: {
      targetName: 'user_preferences_for_event.attendance_type',
      targetMustHaveValue: 'online'
    }
  },
  'user_preferences_for_event.available_for_meetings_to': {
    valueGreaterThan: {
      field: 'user_preferences_for_event[available_for_meetings_from]'
    }
  },
}

if (onlineMeetingInfo) {
  constraints['user_preferences_for_event.online_meeting_info'] = {
    presenceIf: {
      targetName: 'user_preferences_for_event.attendance_type',
      targetMustHaveValue: ['online', 'physical']
    }
  }
}

const toggleFieldsForOnlineEvents = (event) => {
  const fieldsWrapper = document.querySelector('.c-user-preferences-for-event__only-online')
  const currentValue = event.target.value

  if (currentValue === 'online') {
    dom.removeClass(fieldsWrapper, 'u-hidden')
    return
  }

  dom.addClass(fieldsWrapper, 'u-hidden')
}

dom.ready(() => {
  const attendanceTypeField = document.querySelector('[name="user_preferences_for_event[attendance_type]"]')

  if (!attendanceTypeField) { return }

  attendanceTypeField.addEventListener('change', toggleFieldsForOnlineEvents)

  const event = new Event('change')
  attendanceTypeField.dispatchEvent(event)

  setValidations(
    '.c-form--user-preferences-for-event',
    constraints,
    onPreferencesSubmit,
    (errors) => {
      console.log(errors)
    }
  )
})
